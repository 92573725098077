import './styles.css';
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import type { AppProps } from './types';
import App from './App';
import '@fabric-ds/elements';

const app = document.getElementById('realestate-sold-module-podlet');
const dataNode = document.getElementById('realestate-sold-data');

if (app && dataNode && dataNode.textContent) {
    const initialState: AppProps = JSON.parse(dataNode.textContent);

    hydrateRoot(app, <App {...initialState} />);
} else {
    // eslint-disable-next-line no-lonely-if
    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log('missing app and/or data element');
    }
}
